body {
  background-image: url(./Assest/Images/vanishing-stripes.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

 /* change swiper  Pagination Bullets color */

 .swiper-pagination-bullet-active-next,
 .swiper-pagination-bullet-active-next-next,
 .swiper-pagination-bullet-active-prev {
   background-color: white !important;
   opacity: 1 !important;
 }


.pointer {
  cursor: pointer;
  background-size: cover;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #002163 0%,
    #004e92 51%,
    #000022 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff !important;
  border-radius: 100px;
  border: 0;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.offcanvas-backdrop.show {
  opacity: 1;
  background-color: rgba(15, 23, 42, 1);
  backdrop-filter: blur(8px);
}

.offcanvas,
.dropdown-menu.show {
  background-color:#001D56 !important;
}

.dropdown-menu.show{
  border-color: white !important;
}

a.dropdown-item,
.dropdown-toggle{
  color: white !important;
}

a.dropdown-item:hover{
    background-color: #0D6EFD !important;
  }

.navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
